import { UserProfileData } from '../../api-utils/service-requests/user-sessions'
import { Backdrop } from '../../components/backdrop'
import SideDrawer from '../../components/side-drawer'
import { useUserProfileSheet } from './context'
import { UserProfileSheetContent } from './UserProfileSheetContent'

type Props = {
  userProfile: UserProfileData | null
}

export const UserProfileSheet = ({ userProfile }: Props) => {
  const userProfileSheet = useUserProfileSheet()
  const portalId = 'user-profile-sheet-portal-root'
  const closeProfileSheet = () => {
    userProfileSheet.setIsSheetOpen(false)
  }
  const isOpen = userProfileSheet.isSheetOpen
  return (
    <>
      <Backdrop shouldShow={isOpen} onClickCallback={closeProfileSheet} />
      <SideDrawer portalId={portalId} isOpen={isOpen} onCloseClick={closeProfileSheet}>
        <UserProfileSheetContent userProfile={userProfile} />
      </SideDrawer>
    </>
  )
}
