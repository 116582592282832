import { Types } from '@karmalicious/realtime-sdk'
import { useContext, useEffect } from 'react'

import { RealtimeContext } from './RealtimeProvider'

export const useListenForEventKFB = <T extends Types.MessageEvent>(
  event: T,
  id: string,
  callback: Types.OnMessage<T>
) => {
  const context = useContext(RealtimeContext)
  useEffect(() => {
    context.addHandler(event, { id, callback })
    return () => {
      context.removeHandler(event, id)
    }
    // This is disabled because it causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
