import { useRouter } from 'next/router'
import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { v4 } from 'uuid'

import { useMountAndUnmountPortal, usePortalRoot } from '../usePortal'
import { Toast } from './toast'

export type ToastContent = {
  title: string
  body: string | string[]
  type: 'success' | 'error' | 'info'
  autoDismiss?: boolean
  // Provide an optional cid to associate the toast with a specific error. This is shown in a very
  // toned down way in the UI, but can be used to provide more context to someone who is
  // troubleshooting.
  errorCid?: string
}

export interface IToast extends ToastContent {
  id: string
  hidden: boolean
  hide: () => void
}

export type ToastContext = {
  addToast: (content: ToastContent) => void
}

const toastContext = createContext<ToastContext>({
  addToast: () => console.warn('toastContext has not been initialized'),
})

export const ToastsProvider: FC = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([])

  const router = useRouter()

  const portalId = `toasts-portal-root-${router.pathname}`
  const portalRoot = usePortalRoot(portalId)
  useMountAndUnmountPortal(portalRoot)

  const addToast = useCallback(({ autoDismiss = true, ...rest }: ToastContent) => {
    const toastId = v4()

    const toast: IToast = {
      id: toastId,
      autoDismiss,
      hidden: false,
      ...rest,
      hide: () => {
        setToasts((latestToasts) =>
          latestToasts.map((t) => {
            if (t.id === toastId) {
              return { ...t, hidden: true }
            }
            return t
          })
        )
      },
    }

    setToasts((latestToasts) => [...latestToasts, toast])
  }, [])

  const contextValue = useMemo(
    () => ({
      addToast,
    }),
    [addToast]
  )

  const isAllToastsHidden = useMemo(() => toasts.every((t) => t.hidden), [toasts])

  return (
    <toastContext.Provider value={contextValue}>
      {children}
      {createPortal(
        <div
          className={`fixed top-0 right-0 max-w-full xs:max-w-sm xs:w-sm left-0 z-toast ${
            isAllToastsHidden ? 'hidden' : 'm-4'
          }`}>
          {toasts.map((toast) => (
            <div className="mb-2" id="ignoreByCloseAlerter" key={toast.id}>
              <Toast {...toast} />
            </div>
          ))}
        </div>,
        portalRoot
      )}
    </toastContext.Provider>
  )
}

export const useToast = () => useContext(toastContext)
