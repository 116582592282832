import {
  faDoorOpen,
  faGear,
  faGift,
  faLifeRing,
  faReceipt,
  faUser,
  faWallet,
} from '@fortawesome/pro-duotone-svg-icons'
import React from 'react'

import { UserProfileData } from '../../api-utils/service-requests/user-sessions'
import { ActionButton } from '../../components/ActionButton'
import { _T, useTranslations } from '../../components/helpers'
import { Heading } from '../../ui/Typography'
import { useUserProfilePresenter } from '.'
import { SignInBenefitsBlock } from '../../components/SignInBenefitsBlock'

type Props = {
  userProfile: UserProfileData | null
}
export const UserProfileSheetContent = (props: Props) => {
  const tr = useTranslations(translations)
  const userPresenter = useUserProfilePresenter(props.userProfile)

  if (!props.userProfile) {
    return <SignedOutContent />
  }

  let title = userPresenter.fullName
  const titleMaxLen = 30
  if (title.length > titleMaxLen) {
    title = title.substring(0, titleMaxLen) + '...'
  }

  return (
    <>
      <div className="container">
        <Heading level={1} weight="medium" className="mb-4">
          {title}
        </Heading>
        <div className="flex flex-row">
          <ActionButton kind="square" icon={faLifeRing} text={tr.help} href={tr.helpUrl} />
          <ActionButton
            kind="square"
            icon={faWallet}
            text={tr.wallet}
            comingSoonText={tr.comingSoon}
          />
          <ActionButton
            kind="square"
            icon={faReceipt}
            text={tr.previousOrders}
            href="/user/orders"
          />
        </div>
      </div>
      <div className="flex flex-col border-t border-gray-300 mt-8">
        <div className="container">
          <ActionButton icon={faGift} text={tr.loyaltyPrograms} comingSoonText={tr.comingSoon} />
          <ActionButton icon={faUser} text={tr.profile} href="/user/profile" />
          <ActionButton icon={faGear} text={tr.settings} href="/user/settings" />
        </div>
      </div>
    </>
  )
}

const SignedOutContent = () => {
  const tr = useTranslations(translations)
  return (
    <>
      <div className="container">
        <Heading level={1} weight="medium" className="mb-4">
          {tr.signInTitle}
        </Heading>
      </div>
      <div className="flex flex-col border-t border-gray-300 mt-8">
        <div className="container">
          <ActionButton icon={faDoorOpen} text={tr.signInButtonText} href="/sign-in" />
        </div>
        <div className="mx-4">
          <SignInBenefitsBlock />
        </div>
      </div>
    </>
  )
}

export const translations = _T({
  en: {
    signInTitle: 'Menu',
    signInButtonText: 'Sign in to your Karma account',
    previousOrders: 'Orders',
    wallet: 'Wallet',
    help: 'Help',
    helpUrl: 'https://support.karma.life',
    comingSoon: 'Coming soon',

    profile: 'Profile',
    loyaltyPrograms: 'Loyalty programs',
    settings: 'Settings',

    profileMissingName: 'Name missing',
  },
  sv: {
    signInTitle: 'Meny',
    signInButtonText: 'Logga in med ditt Karma-konto',
    previousOrders: 'Beställningar',
    wallet: 'Plånbok',
    help: 'Hjälp',
    helpUrl: 'https://support.karma.life',
    comingSoon: 'Kommer snart',
    profile: 'Profil',
    loyaltyPrograms: 'Lojalitetsprogram',
    settings: 'Inställningar',
    profileMissingName: 'Namn saknas',
  },
  no: {
    signInTitle: 'Meny',
    signInButtonText: 'Sign in to your Karma account',
    previousOrders: 'Bestillinger',
    wallet: 'Lommebok',
    help: 'Hjelp',
    helpUrl: 'https://support.karma.life',
    comingSoon: 'Kommer snart',
    profile: 'Profil',
    loyaltyPrograms: 'Lojalitetsprogram',
    settings: 'Innstillinger',
    profileMissingName: 'Navn mangler',
  },
})
