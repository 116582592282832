import { faBadgeCheck, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import {
  faBadgeCheck as faBadgeCheckSolid,
  faBellRing,
  faX,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Transition } from '@headlessui/react'
import React, { useEffect } from 'react'

import { IToast } from './'

const autoDismissAfterMs = 8000

export const Toast = ({ title, body, type, autoDismiss, hidden, hide, errorCid }: IToast) => {
  useEffect(() => {
    if (autoDismiss) {
      const timeoutHandle = setTimeout(hide, autoDismissAfterMs)

      return () => clearTimeout(timeoutHandle)
    }
  }, [autoDismiss, hide])

  const colors = {
    error: 'bg-karma-pink-400',
    success: 'bg-inline-success',
    info: 'bg-inline-info',
  }

  const icons = {
    error: <FontAwesomeIcon icon={faCircleExclamation} className="text-red-400" size="xl" />,
    success: (
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faBadgeCheck} className="text-inverted" size="xl" />
        <FontAwesomeIcon icon={faBadgeCheckSolid} className="text-default" size="xl" />
      </span>
    ),
    info: <FontAwesomeIcon icon={faBellRing} className="text-default" size="xl" />,
  }

  return (
    <Transition
      show={!hidden}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`my-1 w-full shadow-lg rounded-lg pointer-events-auto text-default ${colors[type]}`}>
      <div className="rounded-lg shadow-xs overflow-hidden cursor-pointer" onClick={hide}>
        <div className="p-4">
          <div className="flex items-center break-words">
            <div className="flex-shrink-0">{icons[type]}</div>
            <div className="ml-5 flex-1 pt-0.5">
              <p className="text-sm leading-5 font-bold">{title}</p>
              <p className="text-sm leading-5">{body}</p>
              {errorCid && <span className="mt-1 text-sm opacity-30">{errorCid}</span>}
            </div>
            <div className="flex-shrink-0 flex">
              <button
                onClick={hide}
                className="inline-flex focus:outline-none transition ease-in-out duration-150">
                <FontAwesomeIcon icon={faX} size="sm" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
