import axios from 'axios'
import { UUID } from 'io-ts-types'
import { filter, flatten, isNull } from 'lodash'
import nookies from 'nookies'
import React from 'react'
import { createContext, FC, useCallback, useContext, useState } from 'react'
import useSWR from 'swr'

import {
  ReadCartResponse,
  SendOrderToKitchenResponse,
} from '../../api-utils/service-requests/cart.types'
import { useClientSideApiHandler } from '../../api-utils/service-requests/utils'
import { CartItem } from '../../components/cart-drawer/cart-items/types'
import { TRANSLATION_COOKIE } from '../../utils/constants'
import { getHeadersWithSentryTraceId } from '../../utils/metrics'
import { useListenForEventKFB } from '../useListenForRealtimeEvent/useListenForEventKFB'
import * as controllers from './controllers'
import { AddItemProps, AddItemResponse } from './controllers/add-item/types'
import { RemoveProps, RemoveResponse } from './controllers/remove-many/types'
import { SendOrderProps } from './controllers/send-order/types'
import { UpdateProps } from './controllers/update-items/types'
import { CartContext, CartReadResponse } from './types'

const cartContext = createContext<CartContext>({
  cart: null,
  currentKitchenDispatchId: null,
  addItem: async () => Promise.resolve({ kitchenDispatchId: null, kitchenDispatchItems: null }),
  removeMany: async () => console.warn('cartContext has not been initialized'),
  updateItems: async () => console.warn('cartContext has not been initialized'),
  sendOrder: async () => console.warn('cartContext has not been initialized'),
  refetchCart: async () => console.warn('cartContext has not been initialized'),
  handleQuantityChange: async () => console.warn('cartContext has not been initialized'),
  handleItemChange: async () => console.warn('cartContext has not been initialized'),
  handleAddNewItem: async () => {
    throw Error('cartContext has not been initialized')
  },
})

export const CartProvider: FC = ({ children }) => {
  const addItemHandler = useClientSideApiHandler(AddItemResponse)
  const removeItemHandler = useClientSideApiHandler(RemoveResponse)
  const readCartHandler = useClientSideApiHandler(ReadCartResponse)
  const sendOrderToKitchenHandler = useClientSideApiHandler(SendOrderToKitchenResponse)

  const [currentKitchenDispatchId, setCurrentKitchenDispatchId] = useState<UUID>()

  const lang = nookies.get(null, { path: '/' })[TRANSLATION_COOKIE]

  const { data, error, mutate } = useSWR<CartReadResponse>(
    lang ? `/api/cart/read?lang=${lang}` : `/api/cart/read`,
    async (url: string) => {
      try {
        const cartData = await axios
          .get(url, {
            validateStatus: () => true,
            headers: getHeadersWithSentryTraceId({}),
          })
          .then(readCartHandler)
        const cart = cartData?.data
        return {
          cart,
          success: true,
        }
      } catch (error) {
        return {
          success: false,
          code: error instanceof Error ? error.message : 'UNKNOWN',
        }
      }
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  )

  if (error && error instanceof Error) {
    console.error(`Fetching cart failed due to ${error.message}`, error)
  }

  const refetchCart = useCallback(async () => {
    mutate()
  }, [mutate])

  const getSelectionFromCartItem = (cartItem: CartItem | undefined) => {
    const kdItems = cartItem?.kitchen_dispatch_items
    if (!kdItems) {
      return undefined
    }
    const variantsInKdItems = flatten(kdItems.map((kdi) => kdi.variant_selection ?? []))
    const variantIds = variantsInKdItems.map((v) => v.variant_id)
    return new Set(variantIds)
  }

  const handleAddNewItem = (
    numItemsToAdd: number,
    selectedChoiceIds: Set<number>,
    commentText: string,
    saleItemUUID: UUID
  ) => {
    return contextValue.addItem({
      saleItemUUID: saleItemUUID,
      tabId: cart?.tabId as UUID,
      quantity: numItemsToAdd,
      variantIds: [...selectedChoiceIds],
      commentText: commentText,
    })
  }

  const handleRemoveItem = async (
    cartItem: CartItem,
    tabId: UUID,
    numItemsInCart: number,
    quantityDiff: number
  ) => {
    const numItemsToRemove = Math.min(numItemsInCart, Math.abs(quantityDiff))
    const itemsToRemove = cartItem.kitchen_dispatch_items.slice(-numItemsToRemove)
    const idsToRemove = itemsToRemove?.map(({ id }) => id)
    await contextValue.removeMany({ tabId, kitchenDispatchItemIds: idsToRemove })
    // need to refetch cart because it is not updated in the response
    await refetchCart()
  }

  const handleItemChange = async (
    quantity: number,
    cartItem: CartItem,
    selectedChoiceIds: Set<number>,
    commentText: string
  ) => {
    const tabId = cart?.tabId as UUID
    const numItemsInCart = cartItem.kitchen_dispatch_items.length || 0
    const quantityDiff = quantity - numItemsInCart
    if (quantityDiff < 0) {
      await handleRemoveItem(cartItem, tabId, numItemsInCart, quantityDiff)
    } else if (quantityDiff > 0) {
      await handleAddNewItem(quantityDiff, selectedChoiceIds, commentText, cartItem?.sku as UUID)
    }
    const itemsToUpdate = cartItem.kitchen_dispatch_items.slice(0, quantity)
    const idsToUpdate = itemsToUpdate.map(({ id }) => id)
    if (idsToUpdate.length) {
      const variantIds = [...selectedChoiceIds]
      await contextValue.updateItems({
        tabId,
        kitchenDispatchItemIds: idsToUpdate,
        variantIds,
        commentText,
      })
    }
  }

  const handleQuantityChange = async (quantity: number, cartItem: CartItem) => {
    const tabId = cart?.tabId as UUID
    const numItemsInCart = cartItem.kitchen_dispatch_items.length || 0
    const quantityDiff = quantity - numItemsInCart

    if (quantityDiff < 0) {
      await handleRemoveItem(cartItem, tabId, numItemsInCart, quantityDiff)
    } else if (quantityDiff > 0) {
      const commentText = cartItem.comment?.comment_text || ''
      const selectedChoiceIds = getSelectionFromCartItem(cartItem) || new Set<number>()
      await handleAddNewItem(quantityDiff, selectedChoiceIds, commentText, cartItem?.sku as UUID)
    }
  }

  useListenForEventKFB('TAB_UPDATED', 'useCart.refresh.tab-updated', refetchCart)
  useListenForEventKFB('KITCHEN_DISPATCH_UPDATED', 'useCart.refresh.kd-updated', refetchCart)
  useListenForEventKFB('KITCHEN_DISPATCH_ITEMS_CHANGED', 'useCart.refresh.kdi-updated', refetchCart)
  useListenForEventKFB('TAB_SETTLEMENT_UPDATED', 'useCart.refresh.ts-updated', refetchCart)

  const cart =
    data && data.cart
      ? {
          ...data.cart,
          items: filter(data.cart.items, (item) => !isNull(item.sku)),
        }
      : null

  const kitchenDispatchId = currentKitchenDispatchId || cart?.kitchenDispatchId || null
  const contextValue = {
    // cart can be undefined so force type to null
    cart,
    currentKitchenDispatchId: kitchenDispatchId,
    addItem: async (props: AddItemProps) => {
      const response = await controllers.addItem({
        data,
        mutate,
        apiHandler: addItemHandler,
        ...props,
      })
      if (response && response.kitchenDispatchId) {
        setCurrentKitchenDispatchId(response.kitchenDispatchId)
      }
      return response
    },
    removeMany: async (props: RemoveProps) => {
      const response = await controllers.removeMany({
        mutate,
        apiHandler: removeItemHandler,
        ...props,
      })
      return response
    },
    updateItems: async (props: UpdateProps) => {
      const response = await controllers.updateItems({
        mutate,
        apiHandler: removeItemHandler,
        ...props,
      })
      return response
    },
    sendOrder: async (props: SendOrderProps) =>
      controllers.sendOrderToKitchen({
        mutate,
        apiHandler: sendOrderToKitchenHandler,
        ...props,
      }),
    refetchCart,
    handleQuantityChange,
    handleAddNewItem,
    handleItemChange,
  }

  return <cartContext.Provider value={contextValue}>{children}</cartContext.Provider>
}

export const useCart = () => useContext(cartContext)
