import * as Sentry from '@sentry/nextjs'
import * as Ably from 'ably'
import { AblyProvider } from 'ably/react'
import React, { useEffect } from 'react'

import { CartProvider as InternalCartProvider, ToastsProvider } from '../../hooks'
import { CurrencyContext, DEFAULT_CURRENCY_CODE } from '../../hooks/useCurrency'
import { RealtimeProvider } from '../../hooks/useListenForRealtimeEvent/RealtimeProvider'
import { SessionExpirationCheck, SessionProvider } from '../../hooks/useSession'
import { UserProfileSheetProvider } from '../../hooks/useUserProfile/context'
import { LocationInformation } from '../../models/types'
import { ErrorFallback } from '../error-fallback'
import { SessionfulPageProps, SessionlessPageProps } from './types'

const getCurrencyContextValueFromProps = (
  props: SessionfulPageProps | SessionlessPageProps
): CurrencyContext => {
  const { information } = props
  const currencyCode = information?.currencyCode ?? DEFAULT_CURRENCY_CODE
  return { currencyCode }
}

const getUserSessionFeatureFlagFromProps = (
  pageProps: SessionfulPageProps | SessionlessPageProps
) => {
  if (pageProps && typeof pageProps === 'object' && 'information' in pageProps) {
    const pp = pageProps as Record<'information', LocationInformation | undefined | null>
    const information = pp.information
    return information?.isUserSessionsEnabled ?? false
  }
  return false
}

export const SessionfulPage: React.FC<SessionfulPageProps> = ({ children, ...pageProps }) => {
  const { session } = pageProps

  const { sessionId, clientId } = session
  const ablyClient =
    clientId && new Ably.Realtime.Promise({ key: pageProps.ablySubscriptionToken, clientId })

  useEffect(() => {
    if (!ablyClient) {
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!session) {
    return null
  }

  const isUserSessionFeatureEnabled = getUserSessionFeatureFlagFromProps(pageProps)

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback code={'SESSIONFUL_PROVIDER_WRAPPER'} />}>
      <SessionProvider session={pageProps.session}>
        <CurrencyContext.Provider value={getCurrencyContextValueFromProps(pageProps)}>
          <AblyProvider client={ablyClient}>
            <RealtimeProvider
              ablySubscriptionToken={pageProps.ablySubscriptionToken}
              sessionId={sessionId}>
              <SessionExpirationCheck>
                <InternalCartProvider>
                  <ToastsProvider>
                    <UserProfileSheetProvider
                      userProfile={pageProps.userProfile}
                      isFeatureEnabled={isUserSessionFeatureEnabled}>
                      <Sentry.ErrorBoundary
                        fallback={<ErrorFallback code={'SESSIONFUL_PAGE_CONTENT'} />}>
                        {children}
                      </Sentry.ErrorBoundary>
                    </UserProfileSheetProvider>
                  </ToastsProvider>
                </InternalCartProvider>
              </SessionExpirationCheck>
            </RealtimeProvider>
          </AblyProvider>
        </CurrencyContext.Provider>
      </SessionProvider>
    </Sentry.ErrorBoundary>
  )
}

export const SessionlessPage: React.FC<SessionlessPageProps> = (props) => {
  const { children } = props
  const isUserSessionFeatureEnabled = getUserSessionFeatureFlagFromProps(props)
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback code={'SESSIONLESS_PROVIDER_WRAPPER'} />}>
      <ToastsProvider>
        <UserProfileSheetProvider
          userProfile={props.userProfile}
          isFeatureEnabled={isUserSessionFeatureEnabled}>
          <CurrencyContext.Provider value={getCurrencyContextValueFromProps(props)}>
            <Sentry.ErrorBoundary fallback={<ErrorFallback code={'SESSIONLESS_PAGE_CONTENT'} />}>
              {children}
            </Sentry.ErrorBoundary>
          </CurrencyContext.Provider>
        </UserProfileSheetProvider>
      </ToastsProvider>
    </Sentry.ErrorBoundary>
  )
}
