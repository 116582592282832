import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect } from 'react'

import { Session } from '../../api-utils/Session'
import { setUserForProfiling } from '../../utils/metrics'
import { useListenForEventKFB } from '../useListenForRealtimeEvent/useListenForEventKFB'

const SessionContext = createContext<Session | null>(null)

interface Props {
  session: Session
}

export const SessionProvider: React.FC<Props> = ({ session, children }) => {
  useEffect(() => {
    setUserForProfiling({
      clientId: session.clientId,
      storefrontSessionId: session.sessionId,
    })
  })

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}

export const useSession = (): {
  session: Session | null
  isTableOrder: boolean
  isTakeAway: boolean
  isRoomService: boolean
  tableName: string | null
} => {
  const session = useContext(SessionContext)
  if (session === null) {
    return {
      session: null,
      isTableOrder: false,
      isTakeAway: false,
      isRoomService: false,
      tableName: null,
    }
  }

  const isTableOrder = session.delivery.kind === 'TABLE_ORDER'
  const isTakeAway = session.delivery.kind === 'TAKEOUT'
  const isRoomService = Boolean(session.qrCodeParams?.room) || false

  return {
    session,
    isTableOrder,
    isTakeAway,
    isRoomService,
    tableName: session.delivery.kind === 'TABLE_ORDER' ? session.delivery.tableName : null,
  }
}

export const SessionExpirationCheck: React.FC = ({ children }) => {
  const router = useRouter()
  const { session } = useSession()
  useListenForEventKFB('SESSION_EXPIRED', 'SessionProvider.redirectOnExpired', () => {
    let destination = `/session-expired`
    if (session) {
      destination += `?delivery=${session.delivery.kind}`
    }
    setUserForProfiling(null)
    router.push(destination)
  })
  return <>{children}</>
}
