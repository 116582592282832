import React, { useContext } from 'react'

import { CurrencyCode } from '../../models/types'

export const DEFAULT_CURRENCY_CODE: CurrencyCode = 'SEK'

export type CurrencyContext = {
  currencyCode: CurrencyCode
}

export const CurrencyContext = React.createContext<CurrencyContext>({
  currencyCode: DEFAULT_CURRENCY_CODE,
})

export const useCurrency = () => {
  const context = useContext(CurrencyContext)
  return { currencyCode: context.currencyCode }
}
