import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { CSSProperties } from 'react'

export type DuoToneBaseColor = {
  r: number
  g: number
  b: number
}

export type DuoToneIconProps = { baseColorRgb: DuoToneBaseColor } & FontAwesomeIconProps

/**
 * Simple wrapper around FontAwesomeIcon that allows setting the primary and secondary colors
 * for duo-tone icons.
 */
export const DuoToneIcon = (props: DuoToneIconProps) => {
  const { baseColorRgb, ...rest } = props
  const rgba = (a: number) => `rgba(${baseColorRgb.r}, ${baseColorRgb.g}, ${baseColorRgb.b}, ${a})`
  return (
    <FontAwesomeIcon
      {...rest}
      style={
        {
          '--fa-primary-color': `${rgba(1.0)}`,
          '--fa-secondary-color': `${rgba(0.6)}`,
        } as CSSProperties // fix unknown prop issue
      }
    />
  )
}
