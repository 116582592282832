import { useEffect, useRef } from 'react'

function createPortalRoot(id: string) {
  const portalRoot = document.createElement('div')
  portalRoot.setAttribute('id', id)
  return portalRoot
}

export function usePortalRoot(id: string) {
  const portalRoot = useRef(document.getElementById(id) || createPortalRoot(id))
  return portalRoot.current
}

export function useMountAndUnmountPortal(portalRoot: HTMLElement) {
  useEffect(
    () => {
      document.body.appendChild(portalRoot)

      return () => {
        portalRoot.remove()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
