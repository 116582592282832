import axios from 'axios'

import { getHeadersWithSentryTraceId } from '../../../../utils/metrics'
import { Props } from './types'

export const sendOrderToKitchen = async ({
  kitchenDispatchId,
  mutate,
  apiHandler,
}: Props): Promise<void> => {
  try {
    if (!kitchenDispatchId) {
      console.error('sendOrderToKitchen() Missing kitchen dispatch ID')
      return
    }
    await axios
      .post(
        '/api/cart/send-order',
        {
          kitchenDispatchId,
        },
        { validateStatus: () => true, headers: getHeadersWithSentryTraceId({}) }
      )
      .then(apiHandler)

    mutate()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(`Could send order to kitchen due to ${error.message}`)
  }
}
