import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons'
import React from 'react'

import { DuoToneIcon, DuoToneIconProps } from '../ui/Icons/DuoToneIcon'
import { Text } from '../ui/Typography'

const GrayIcon = (props: Omit<DuoToneIconProps, 'baseColorRgb'>) => {
  return <DuoToneIcon baseColorRgb={{ r: 0, g: 0, b: 0 }} {...props} />
}

const RedIcon = (props: Omit<DuoToneIconProps, 'baseColorRgb'>) => {
  return <DuoToneIcon baseColorRgb={{ r: 255, g: 0, b: 0 }} {...props} />
}

type ActionButtonProps = {
  kind?: 'row' | 'square'
  text: string
  icon: IconDefinition
  onClick?: () => Promise<void>
  href?: string
  comingSoonText?: string
  danger?: boolean
  disabled?: boolean
}
export const ActionButton = (props: ActionButtonProps) => {
  const {
    kind = 'row',
    text,
    icon,
    onClick,
    href,
    comingSoonText,
    danger = false,
    disabled = false,
  } = props

  const handleClick = async () => {
    if (disabled) {
      return
    }

    if (href) {
      // NOTE(christoffer) Explicitly use real browser navigation here instead of Next.JS routing
      // because Next will attempt to (briefly) re-render the content of the _sheet_ before doing
      // the navigation, which causes a jank. There's probably some clever flags to force Next.JS
      // to do what we want. But what we want is to navigate to the new page, so let's just do it.
      window.location.href = href
      return
    } else if (onClick) {
      return onClick()
    }
  }

  const Icon = danger ? RedIcon : GrayIcon

  if (kind === 'square') {
    return (
      <div
        className={`flex-1 flex flex-col relative bg-gray-200 h-50 p-4 rounded-md mr-4 last:mr-0 items-center cursor-pointer ${
          disabled ? 'opacity-75' : ''
        }`}
        onClick={handleClick}>
        <div className={`mb-2`}>
          <GrayIcon size={'lg'} icon={icon} />
        </div>
        <Text level={3} weight="medium" className={`${danger ? 'text-500' : ''}`}>
          {text}
        </Text>
        {comingSoonText ? (
          <span className="absolute bottom-0 left-0 right-0 justify-center inline-flex ml-auto items-center bg-green-100 text-xxs font-bold rounded-b-md">
            {comingSoonText}
          </span>
        ) : null}
      </div>
    )
  } else {
    return (
      <div
        className={`flex-1 flex flex-row py-4 px-2 border-inline border-b cursor-pointer`}
        onClick={handleClick}>
        <div className={`mr-4`}>
          <Icon size={'lg'} icon={icon} />
        </div>
        <Text
          color={'custom'}
          level={3}
          weight="medium"
          className={`inline-flex items-center ${danger ? 'text-[#FF0000]' : ''}`}>
          {text}
        </Text>
        {comingSoonText ? (
          <span className="inline-flex ml-auto items-center px-2 bg-green-100 text-xxs font-bold rounded-full">
            {comingSoonText}
          </span>
        ) : null}
      </div>
    )
  }
}
