import axios from 'axios'

import { getHeadersWithSentryTraceId } from '../../../../utils/metrics'
import { Props } from './types'

export const updateItems = async ({
  tabId,
  kitchenDispatchItemIds,
  variantIds,
  commentText,
  apiHandler,
  mutate,
}: Props) => {
  try {
    const responseData = await axios
      .post(
        '/api/cart/update-items',
        {
          tabId,
          kitchenDispatchItemIds,
          variantIds,
          commentText,
        },
        {
          headers: getHeadersWithSentryTraceId({}),
        }
      )
      .then(apiHandler)
    mutate()
    return responseData
  } catch (error) {
    console.error(
      `Error while update items with uuids ${kitchenDispatchItemIds} using variant ids ${variantIds}: ${error}`,
      error
    )
  }
}
