import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { PAGE_TRANSITION_DURATION_MS } from '../../utils/constants'

export const useIsRouting = () => {
  const router = useRouter()
  const [isRouting, setIsRouting] = useState(false)

  useEffect(() => {
    const handleRouteStart = () => {
      setIsRouting(true)
    }

    const handleRouteComplete = () => {
      // set isRouting to false after a timeout to allow the drawer to animate in after page transition is complete

      setTimeout(() => {
        setIsRouting(false)
      }, PAGE_TRANSITION_DURATION_MS + 200)
    }

    router.events.on('routeChangeStart', handleRouteStart)
    router.events.on('routeChangeComplete', handleRouteComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteStart)
      router.events.off('routeChangeComplete', handleRouteComplete)
    }
  }, [router.events])

  return isRouting
}
