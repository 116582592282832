import { UserProfileData } from '../../api-utils/service-requests/user-sessions'
import { _T, useTranslations } from '../../components/helpers'

type UserProfilePresentation = {
  firstName: string
  fullName: string
}

export const useUserProfilePresenter = (
  userProfile: UserProfileData | undefined | null
): UserProfilePresentation => {
  const tr = useTranslations(translations)

  let firstName: string
  let fullName: string

  const { displayName } = userProfile ?? {}
  if (displayName && displayName.length) {
    fullName = displayName
    firstName = displayName.split(' ')[0]
  } else {
    fullName = tr.missingName
    firstName = tr.missingName
  }

  return {
    fullName,
    firstName,
  }
}

const translations = _T({
  en: {
    missingName: 'Karma user',
  },
  sv: {
    missingName: 'Karma-användare',
  },
  no: {
    missingName: 'Karma-bruker',
  },
})
