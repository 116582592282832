import { useRouter } from 'next/router'
import React from 'react'

import { Button } from '../ui/Button/Button'
import { useTranslations } from './helpers'

const translations = {
  en: {
    title: 'Oops!',
    reload: 'Reload page',
    errorCode: (code: string) =>
      `The application crashed with the error code ${code}. We're really sorry about that.`,
  },
  sv: {
    title: 'Hoppsan!',
    reload: 'Ladda om sidan',
    errorCode: (code: string) => `Sidan krashade med felkoden ${code}. Vi beklagar. Verkligen.`,
  },
  no: {
    title: 'Oops!',
    reload: 'Last siden på nytt',
    errorCode: (code: string) => `Siden krasjet med feilkode ${code}. Vi beklager virkelig.`,
  },
}

interface Props {
  code: string
}

export const ErrorFallback: React.FC<Props> = ({ code }) => {
  const copy = useTranslations(translations)
  const router = useRouter()
  return (
    <div className="flex items-center justify-center min-h-screen h-screen pt-4 px-4 pb-20 text-center">
      <div className="border border-karma-pink-300 shadow rounded-md p-4 max-w-sm w-full mx-auto ">
        <div className="flex space-x-4">
          <div className="flex-1 space-y-4 py-1">
            <h3 className="font-medium text-lg">{copy.title}</h3>
            <p>{copy.errorCode(code)}</p>
            <Button onClick={router.reload}>{copy.reload}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
