import axios from 'axios'

import { getHeadersWithSentryTraceId } from '../../../../utils/metrics'
import { Props } from './types'

export const removeMany = async ({ tabId, kitchenDispatchItemIds, apiHandler, mutate }: Props) => {
  try {
    const responseData = await axios
      .post(
        '/api/cart/remove-items',
        {
          tabId,
          kitchenDispatchItemIds,
        },
        {
          headers: getHeadersWithSentryTraceId({}),
        }
      )
      .then(apiHandler)
    mutate()
    return responseData
  } catch (error) {
    console.error(
      `Error while removing item with uuids ${kitchenDispatchItemIds} from cart: ${error}`,
      error
    )
  }
}
