import axios from 'axios'

import { addItemRequest } from '../../../../api-utils/service-requests/cart'
import { getHeadersWithSentryTraceId } from '../../../../utils/metrics'
import { AddItemResponse, Props } from './types'

export const addItem = async ({
  tabId,
  saleItemUUID,
  mutate,
  quantity,
  variantIds,
  apiHandler,
  commentText,
}: Props): Promise<AddItemResponse> => {
  try {
    const payload: addItemRequest = {
      tabId,
      saleItemUUID,
      variantIds,
      quantity,
      ...(commentText ? { commentText } : {}),
    }

    const responseData = await axios
      .post<AddItemResponse>('/api/cart/add', payload, {
        validateStatus: () => true,
        headers: getHeadersWithSentryTraceId({}),
      })
      .then(apiHandler)

    if (!responseData) {
      console.error(`Could not add item to cart`)
      return {
        kitchenDispatchId: null,
        kitchenDispatchItems: null,
      }
    }

    if (responseData && responseData.kitchenDispatchId && responseData.kitchenDispatchItems) {
      mutate()
    }

    return {
      ...responseData,
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(`Could not add item to cart due to ${error.message}`)
    throw error
  }
}
