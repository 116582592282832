import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { KeyedMutator } from 'swr'

import { ClientSideApiHandler } from '../../../../api-utils/service-requests/utils'
import { CartReadResponse } from '../../types'

export type RemoveProps = {
  tabId: UUID
  kitchenDispatchItemIds: UUID[]
}

export type Props = RemoveProps & {
  mutate: KeyedMutator<CartReadResponse>
  apiHandler: ClientSideApiHandler<'' | null>
}

export const RemoveResponse = t.null
