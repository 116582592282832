import { XIcon } from '@heroicons/react/solid'
import React, { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

import { useMountAndUnmountPortal, usePortalRoot } from '../../hooks'

type CloseButtonProps = {
  onClick: () => void
}
const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <button
      className={`inline-flex items-center justify-center p-1 text-karma-black-900`}
      onClick={onClick}>
      <XIcon className="w-6 h-6 m-0.5" />
    </button>
  )
}

type Props = PropsWithChildren<{
  classes?: string
  isOpen?: boolean
  portalId: string
  onCloseClick: () => void
}>

const SideDrawer = ({ children, isOpen = false, onCloseClick, portalId }: Props) => {
  // NOTE(christoffer) Use a random id because the portal functionality we have now seems
  // to remove portals and never recreate them when we change pages? Using a random id
  // forces recreation of the portal root element.
  const rnd = Math.floor(Math.random() * 1000000)
  const portalRoot = usePortalRoot(portalId + rnd)
  useMountAndUnmountPortal(portalRoot)

  const transitionClassesIn = 'duration-200'
  const transitionClassesOut = 'duration-200'

  return createPortal(
    <div
      style={{
        transform: isOpen ? 'translate(0, 0)' : 'translate(100%, 0)',
      }}
      className={`fixed h-full max-w-md w-[90%] top-0 right-0 flex flex-col bg-opacity-0 ease-out-cubic duration-200 z-modal`}>
      <div className="h-full bg-white overflow-auto flex flex-col pointer-events-auto shadow">
        <div className="p-2 ml-auto">
          <CloseButton onClick={onCloseClick} />
        </div>
        <div className={`${isOpen ? transitionClassesIn : transitionClassesOut} relative`}>
          {children}
        </div>
      </div>
    </div>,
    portalRoot
  )
}

export default SideDrawer
