/* eslint-disable react-hooks/exhaustive-deps */
import { RealtimeChannel, RealtimeConnection } from '@karmalicious/realtime-sdk'
import { Message, MessageEvent, OnMessage } from '@karmalicious/realtime-sdk/lib/types'
import { UUID } from 'io-ts-types/lib/UUID'
import React, { createContext, FC, useEffect, useMemo, useState } from 'react'

interface ContextState {
  removeHandler: <E extends MessageEvent>(event: E, id: string) => void
  addHandler: <E extends MessageEvent>(event: E, handler: Handler<E>) => void
}
export const RealtimeContext = createContext<ContextState>({
  removeHandler: () => null,
  addHandler: () => null,
})

type Handler<E extends MessageEvent> = { id: string; callback: OnMessage<E> }
type HandlerMap = { [E in MessageEvent]?: Handler<E>[] }

const getHandlerHash = (handlerMap: HandlerMap) => {
  function hashHandler<E extends MessageEvent>(event: string, handlers: Handler<E>[]) {
    return handlers.map((h) => `${event}:${h.id}`).join('|')
  }
  return Object.entries(handlerMap)
    .map(([event, handlers]) =>
      hashHandler<MessageEvent>(event, handlers as Handler<MessageEvent>[])
    )
    .join('||')
}

interface Props {
  sessionId: UUID
  ablySubscriptionToken: string
}
export const RealtimeProvider: FC<Props> = ({ children, sessionId, ablySubscriptionToken }) => {
  const [channel, setChannel] = useState<RealtimeChannel | null>(null)
  const [connection, setConnection] = useState<RealtimeConnection | null>(null)
  const [handlerMap, setHandlerMap] = useState<HandlerMap>({
    TAB_MEMBER_REQUEST: [],
    TAB_MEMBERS_CHANGED: [],
    TAB_UPDATED: [],
    KITCHEN_DISPATCH_ITEMS_CHANGED: [],
    TAB_SETTLEMENT_UPDATED: [],
    SESSION_EXPIRED: [],
    KITCHEN_DISPATCH_UPDATED: [],
  })
  function addHandler<E extends MessageEvent>(event: E, handler: Handler<E>) {
    setHandlerMap((handlerMap) => {
      const previousHandlers = (handlerMap[event] as Handler<E>[]).filter(
        (h) => h.id !== handler.id
      )
      const newHandlers = [...previousHandlers, handler]

      return {
        ...handlerMap,
        [event]: newHandlers,
      }
    })
  }
  function removeHandler<E extends MessageEvent>(event: E, id: string) {
    setHandlerMap((handlerMap) => {
      const previousHandlers = (handlerMap[event] || []) as Handler<E>[]
      const newHandlers = previousHandlers.filter((h) => h.id !== id)
      return {
        ...handlerMap,
        [event]: newHandlers,
      }
    })
  }
  useEffect(() => {
    let localConnection = connection
    if (!localConnection) {
      const cn = RealtimeConnection({
        transport: 'ABLY',
        apiKey: ablySubscriptionToken,
      })
      setConnection(cn)
      localConnection = cn
    }
    const newChannel = localConnection.getChannel({
      kind: 'STOREFRONT_SESSION',
      sessionUUID: sessionId,
    })
    setChannel(newChannel)
    return () => {
      channel && channel.leave()
      connection && connection.disconnect()
    }
  }, [sessionId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!channel) {
      return
    }
    const subs = Message.types
      .map((m) => m.props.event.value)
      .map((event) => {
        const sub = channel.subscribe(event, (message) => {
          const handlers = (handlerMap[event] || []) as Handler<typeof event>[]
          for (const handler of handlers) {
            handler.callback(message)
          }
        })
        return sub
      })
    return () => {
      subs.forEach((sub) => sub.unsubscribe())
    }
  }, [channel, handlerMap])

  const handlerMapHash = getHandlerHash(handlerMap)
  const contextState = useMemo(
    () => ({ removeHandler, addHandler }),
    [sessionId, handlerMapHash, removeHandler, addHandler]
  )
  return <RealtimeContext.Provider value={contextState}>{children}</RealtimeContext.Provider>
}
