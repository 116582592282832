import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { KeyedMutator } from 'swr'

import { LightKitchenDispatchItem } from '../../../../api-utils/service-requests/cart.types'
import { ClientSideApiHandler } from '../../../../api-utils/service-requests/utils'
import { nullable } from '../../../../models/types'
import { CartReadResponse } from '../../types'

export type AddItemProps = {
  tabId: UUID
  saleItemUUID: UUID
  quantity: number
  variantIds: number[]
  commentText?: string | null
}

export type Props = AddItemProps & {
  apiHandler: ClientSideApiHandler<AddItemResponse>
  data: CartReadResponse | undefined
  mutate: KeyedMutator<CartReadResponse>
}

export const AddItemResponse = t.type({
  kitchenDispatchId: nullable(UUID),
  kitchenDispatchItems: nullable(t.array(LightKitchenDispatchItem)),
})
export type AddItemResponse = t.TypeOf<typeof AddItemResponse>

export type AddItemApiRouteResponse = {
  success: boolean
  code?: string
  data?: AddItemResponse
}
