import { createContext, PropsWithChildren, useContext, useState } from 'react'

import { UserProfileData } from '../../api-utils/service-requests/user-sessions'
import { UserProfileSheet } from './UserProfileSheet'

export type UserProfileSheetContext = {
  isEnabled: boolean
  isSheetOpen: boolean
  setIsSheetOpen: (isSheetOpen: boolean) => void
}

const Context = createContext<UserProfileSheetContext>({
  isEnabled: false,
  isSheetOpen: false,
  setIsSheetOpen: () => ({}),
})

type Props = {
  userProfile?: UserProfileData | null
  isFeatureEnabled: boolean
}

export const UserProfileSheetProvider = ({
  children,
  userProfile,
  isFeatureEnabled = false,
}: PropsWithChildren<Props>) => {
  const [isSheetOpen, _setIsSheetOpen] = useState(false)

  const setIsSheetOpen = (isSheetOpen: boolean) => {
    if (!isFeatureEnabled) {
      return
    }
    _setIsSheetOpen(isSheetOpen)
  }
  const isEnabled = isFeatureEnabled // && isDisabledForLocation(...), etc.

  return (
    <Context.Provider value={{ isEnabled, isSheetOpen, setIsSheetOpen }}>
      {isFeatureEnabled ? <UserProfileSheet userProfile={userProfile ?? null} /> : null}
      {children}
    </Context.Provider>
  )
}

export const useUserProfileSheet = () => {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error('useUserProfileSheet() must be used within a UserProfileSheetProvider')
  }
  return context
}
