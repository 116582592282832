import { IconLookup } from '@fortawesome/fontawesome-common-types'
import { faGaugeMax, faGift, faStars } from '@fortawesome/pro-duotone-svg-icons'

import { DuoToneBaseColor, DuoToneIcon } from '../ui/Icons/DuoToneIcon'
import { Heading, Text } from '../ui/Typography'
import { _T, useTranslations } from './helpers'

export const SignInBenefitsBlock = () => {
  const tr = useTranslations(translations)

  return (
    <div className="mt-8 bg-gray-200 p-4 rounded-md">
      <Heading level={4} className="mb-4">
        {tr.header}
      </Heading>
      <div className="mb-4">
        <Row
          icon={faGaugeMax as unknown as IconLookup}
          baseColor={{ r: 151, g: 71, b: 255 }}
          title={tr.item1header}
          body={tr.item1body}
        />
        <Row
          icon={faGift as unknown as IconLookup}
          baseColor={{ r: 0, g: 175, b: 0 }}
          title={tr.item2header}
          body={tr.item2body}
        />
        <Row
          icon={faStars as unknown as IconLookup}
          baseColor={{ r: 255, g: 184, b: 0 }}
          title={tr.item3header}
          body={tr.item3body}
        />
      </div>
    </div>
  )
}

const Row = (props: {
  icon: IconLookup
  baseColor: DuoToneBaseColor
  title: string
  body: string
}) => {
  const { icon, baseColor, title, body } = props
  return (
    <div className="flex flex-row mb-2">
      <DuoToneIcon icon={icon} size="2xl" baseColorRgb={baseColor} />
      <div className="flex flex-col ml-4">
        <Text level={3} weight="bold">
          {title}
        </Text>
        <Text level={3} weight="regular">
          {body}
        </Text>
      </div>
    </div>
  )
}

const translations = _T({
  en: {
    header: 'Benefits of signing in',
    item1header: 'Faster checkout',
    item1body: 'At all restaurants. No more entering details or payment information!',
    item2header: 'Automatic loyalty',
    item2body: 'With built in privacy that you control',
    item3header: 'And much more',
    item3body: 'Save favourites, your allergens, order history and so much more',
  },
  sv: {
    header: 'Fördelar med att logga in',
    item1header: 'Snabbare utcheckning',
    item1body: 'På alla restauranger. Inga fler detaljer eller betalningsinformation!',
    item2header: 'Automatisk lojalitet',
    item2body: 'Med inbyggd integritet som du kontrollerar',
    item3header: 'Och mycket mer',
    item3body: 'Spara favoriter, dina allergener, beställningshistorik och mycket mer',
  },
  no: {
    header: 'Fordeler med å logge inn',
    item1header: 'Raskere utsjekking',
    item1body: 'På alle restauranter. Ingen flere detaljer eller betalingsinformasjon!',
    item2header: 'Automatisk lojalitet',
    item2body: 'Med innebygd personvern som du kontrollerer',
    item3header: 'Og mye mer',
    item3body: 'Lagre favoritter, allergener, bestillingshistorikk og mye mer',
  },
})
